<template>
  <div class="map-popup">
    <div class="map-popup__legend">
      <img src="../assets/images/clock.svg" alt="">
      <span>&Agrave;&nbsp;pied</span>
    </div>
    <div class="map-popup__time">{{ time }}</div>
    <div class="map-popup__distance">{{ distance }}</div>
  </div>
</template>

<script>
export default {
  name: 'MapPopup',
  props: {
    time: String,
    distance: String
  }
}
</script>
