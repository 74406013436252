<template>
  <div class="contextual-help t1">
<!--    <div class="contextual-help__tip">-->
<!--      <Tip back forth="/tutorial2" text="Observez les animations en&nbsp;r&eacute;alit&eacute; augment&eacute;e et&nbsp;suivez la&nbsp;cl&eacute; vers votre nouveau concept store H&amp;M&nbsp;HOME.-->
<!--      Jusqu&rsquo;&agrave; 1000&euro; de&nbsp;cartes cadeaux vous y&nbsp;attendent !" />-->
<!--    </div>-->
    <div class="contextual-help__map">
      <Map />
    </div>
  </div>
</template>

<script>
import Map from '@/components/Map.vue'
import Button from '@/components/Button.vue'
import Tip from '@/components/Tip.vue'

export default {
  name: 'Tutorial1',
  components: {
    Map,
    Button,
    Tip
  },
  mounted: function() {
    store.set('currentPage', 'Tutorial1');
  }
}
</script>

<style lang="scss">
@import "./../assets/styles/variables.scss";

.t1 {
  background: $background-color;
}
.contextual-help__map {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.contextual-help__nav-link {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding: 0 20px 27px;
  z-index: 1;

  .button {
    min-width: 240px;
  }
}
</style>
